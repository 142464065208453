//@flow

import React from 'react'
// import { graphql } from 'gatsby'

import Layout from '../layouts'
import SEO from '../components/SEO.js'
import { CTASubscribe } from '../templates/post'
import config from '../../config'

import { Title } from '../components/Common.js'

export default () => {
  return (
    <Layout title={config.siteTitle + ' | Contact'}>
      <Title
        css={{
          color: '#78abbe',
          fontSize: '3rem',
          lineHeight: '1.5em',
          textAlign: 'center',
        }}
        style={{ marginTop: 40 }}
      >
        So considering joining the list?
      </Title>

      <CTASubscribe title="Good choice! I tell you everything I know." />

      <SEO />
    </Layout>
  )
}

// Set here the ID of the home page.
// export const pageQuery = graphql`
//   query subscribeQuery {
//     allWordpressPost {
//       edges {
//         node {
//           id
//           title
//           excerpt
//           slug
//           date(formatString: "MMMM DD, YYYY")
//           better_featured_image {
//             media_details {
//               sizes {
//                 large {
//                   source_url
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
